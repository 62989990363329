import { Button, Typography, useTheme } from '@mui/material';
import { PixTabs } from '..';

export function CustomButton(props: {
  label: string;
  tab: PixTabs;
  selectedTab: PixTabs;
  setSelectedTab: (input: PixTabs) => void;
}) {
  const { label, tab, selectedTab, setSelectedTab } = props;
  const isSelected = selectedTab == tab;
  const theme = useTheme();

  return (
    <Button
      sx={{
        height: '34px',
        borderRadius: '6px',
        borderColor: isSelected
          ? theme.palette.primary.dark
          : theme.palette.grey[300],
        textTransform: 'none',
        backgroundColor: isSelected ? theme.palette.primary.dark : undefined,
        '&:hover': {
          background: isSelected ? theme.palette.primary.dark : undefined
        }
      }}
      onClick={() => setSelectedTab(tab)}
    >
      <Typography
        fontSize={'12px'}
        fontWeight={'600'}
        color={isSelected ? '#fff' : theme.palette.grey[600]}
      >
        {label}
      </Typography>
    </Button>
  );
}
