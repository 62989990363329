import {
  styled,
  Box,
  Button,
  Typography,
  IconButton as MUIIconButton
} from '@mui/material';

export const ModalTitle = styled(Typography)(
  ({ theme }) => `
  margin-bottom: 16px;
  font-size: 1.375rem;
  color: ${theme.palette.primary.dark};
  font-weight: ${theme.typography.fontWeightLight}
`
);

export const ModalSubTitle = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.grey[600]};
  font-weight: ${theme.typography.fontWeightLight};
`
);

export const IconButton = styled(MUIIconButton)(
  ({ theme }) => `
  top: 12px;
  right: 16px;
  position: absolute;
  color: ${theme.palette.grey[500]};
`
);

export const NoChangeButton = styled(Button)(
  ({ theme }) => `
  margin-top: 24px;
  margin-right: 8px;
  padding: 13.5px;
  font-weight:  ${theme.typography.fontWeightMedium};
  color: ${theme.palette.primary.dark};
  ${theme.breakpoints.down('md')} {
    margin-top: 8px;
  }
`
);

export const ConfirmChangeButton = styled(Button)(
  ({ theme }) => `
  margin-top: 24px;
  padding: 13.5px;
  font-weight: ${theme.typography.fontWeightMedium};
  background-color: ${theme.palette.primary.dark}
`
);

export const ButtonsBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: end;

  ${theme.breakpoints.down('md')} {
    justify-content: center;
    flex-direction: column-reverse;
  }
`
);
