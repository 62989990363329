import {
  AlarmOn,
  AppSettingsAlt,
  ContentCopy,
  Pix,
  Send
} from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

interface IProps {
  pixCodeImage: string;
}

export const QRTabContent = (props: IProps) => {
  const { pixCodeImage } = props;

  return (
    <Stack direction={'column'} gap={'16px'}>
      <Typography fontSize={'16px'} fontWeight={'600'}>
        Copie este código para pagar
      </Typography>
      <Stack direction={'column'} gap={'12px'}>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <ContentCopy sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Copie o código de pagamento abaixo
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <AppSettingsAlt sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Abra o aplicativo do seu banco
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <Pix sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Escolha a opção PIX
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <Send sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Use o {` "escanear QR Code" `}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <AlarmOn sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Aprovação do pix em até 5 minutos
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent={'center'} alignItems={'center'}>
        <img
          src={'data:image/png;base64,' + pixCodeImage}
          width={147}
          height={147}
        />
      </Stack>
    </Stack>
  );
};
