import {
  ButtonsBox,
  IconButton,
  ModalTitle,
  ModalSubTitle,
  NoChangeButton,
  ConfirmChangeButton
} from './styles';
import CustomInput from 'components/CustomInput';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import * as Yup from 'yup';

import { useCheckout } from 'views/Review/hooks/useCheckoutContext';
import { formatCPF } from 'utils/format-cpf';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidCPF } from 'helpers/validate-cpf';
import { useContext, useEffect } from 'react';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

const pixFormSchema = Yup.object({
  cpf: Yup.string().required('Preencha o campo CPF'),
  email: Yup.string()
    .required('Preencha o campo e-mail')
    .email('E-mail inválido')
}).required();

type PixFormData = Yup.InferType<typeof pixFormSchema>;

export default function PixUserDataModal(props: ModalProps) {
  const { onClose, open } = props;

  const { leadData } = useContext(LeadDataContext);
  const { cpf, setCpf, email, setEmail, setFormIsValid } = useCheckout();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<PixFormData>({
    defaultValues: {
      cpf: '',
      email: ''
    },
    resolver: yupResolver(pixFormSchema)
  });

  useEffect(() => {
    if (!email) {
      setEmail(leadData.email);
      setValue('email', leadData.email);
    }
    if (!cpf) {
      setCpf(leadData.cpf);
      setValue('cpf', leadData.cpf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchCpf = watch('cpf');

  function handleSubmitInfos() {
    setFormIsValid(true);
    onClose();
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle sx={{ pt: '24px' }}>
        <ModalTitle>Dados para o pix</ModalTitle>

        <ModalSubTitle>
          Para que avançamos com o pagamento via pix, preencha os seguites
          dados:
        </ModalSubTitle>

        <IconButton aria-label="close-modal" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <form onSubmit={handleSubmit(handleSubmitInfos)}>
        <DialogContent sx={{ pt: 0 }}>
          <Controller
            name="cpf"
            control={control}
            render={({ field }) => (
              <CustomInput
                {...field}
                name="cpf"
                label="CPF do títular"
                value={formatCPF(cpf)}
                onChange={(input) => {
                  field.onChange(input);
                  setCpf(formatCPF(input.target.value));
                }}
                error={Boolean(errors.cpf?.message)}
                helperText={errors.cpf?.message}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <CustomInput
                {...field}
                name="email"
                label="E-mail do títular"
                value={email}
                onChange={(input) => {
                  field.onChange(input);
                  setEmail(input.target.value);
                }}
                error={Boolean(errors.email?.message)}
                helperText={errors.email?.message}
              />
            )}
          />

          <ButtonsBox>
            <NoChangeButton variant="text" onClick={onClose}>
              Cancelar
            </NoChangeButton>

            <ConfirmChangeButton
              variant="contained"
              disabled={!isValidCPF(watchCpf)}
              type="submit"
            >
              Confirmar
            </ConfirmChangeButton>
          </ButtonsBox>
        </DialogContent>
      </form>
    </Dialog>
  );
}
