import {
  AlarmOn,
  AppSettingsAlt,
  CheckCircleOutline,
  ContentCopy,
  Pix,
  Send
} from '@mui/icons-material';
import {
  Stack,
  Typography,
  useTheme,
  IconButton as MuiIconButton,
  TextField
} from '@mui/material';
import { toast } from 'react-toastify';

interface IProps {
  pixCode: string;
}

export const CodeTabContent = (props: IProps) => {
  const { pixCode } = props;
  const theme = useTheme();

  return (
    <Stack direction={'column'} gap={'16px'}>
      <Typography fontSize={'16px'} fontWeight={'600'}>
        Copie este código para pagar
      </Typography>
      <Stack direction={'column'} gap={'12px'}>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <ContentCopy sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Copie o código de pagamento abaixo
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <AppSettingsAlt sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Abra o aplicativo do seu banco
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <Pix sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Escolha a opção PIX
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <Send sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Use o {` "pix copie e cola" `}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <AlarmOn sx={{ fontSize: '16px' }} color="primary" />
          <Typography fontWeight={'400'} fontSize={'14px'} lineHeight={'16px'}>
            Aprovação do pix em até 5 minutos
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        width={'100%'}
        bgcolor={theme.palette.grey[50]}
        height={'56px'}
        border={`solid 1px ${theme.palette.grey[300]}`}
        borderRadius={'8px'}
        justifyContent={'space-between'}
        gap={'10px'}
      >
        <TextField
          value={pixCode}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              color: theme.palette.grey[700],
              fontWeight: '400',
              '& fieldset': {
                border: 'none'
              },
              '&:hover fieldset': {
                border: 'none'
              },
              '&.Mui-focused fieldset': {
                border: 'none'
              }
            }
          }}
          InputProps={{
            readOnly: true
          }}
        />
        <MuiIconButton
          sx={{ width: '50px' }}
          onClick={() => {
            navigator.clipboard.writeText(pixCode);
            toast.success(`Código pix copiado`, {
              autoClose: 5000,
              draggable: true,
              theme: 'colored',
              closeOnClick: true,
              pauseOnHover: true,
              closeButton: false,
              hideProgressBar: false,
              position: 'bottom-center',
              icon: () => <CheckCircleOutline />
            });
          }}
        >
          <ContentCopy color="primary" />
        </MuiIconButton>
      </Stack>
    </Stack>
  );
};
